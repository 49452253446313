// import React, { useState, useEffect, useCallback, useMemo } from "react";
// import {
//   Box,
//   Typography,
//   Chip,
//   Slide,
//   useMediaQuery,
//   Snackbar,
//   Fade,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import { AccessTime, ContentCopy } from "@mui/icons-material";
// import { useTheme } from "@mui/material/styles";

// const StyledBanner = styled(Box)(({ theme }) => ({
//   position: "fixed",
//   left: 0,
//   width: "100%",
//   backgroundColor: "#27B1BB",
//   padding: theme.spacing(1),
//   textAlign: "center",
//   fontSize: "18px",
//   color: "#FFFFFF",
//   zIndex: 999,
//   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   flexWrap: "wrap",
//   gap: theme.spacing(1),
// }));

// const TimerChip = styled(Chip)(({ theme }) => ({
//   fontWeight: "bold",
//   backgroundColor: "#FF6B6B",
//   color: "#FFFFFF",
// }));

// const getExpirationTimestamp = () => {
//   const storedTimestamp = localStorage.getItem("couponExpirationTimestamp");

//   if (storedTimestamp) {
//     return parseInt(storedTimestamp, 10);
//   }

//   const newExpirationTimestamp =
//     new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
//   localStorage.setItem(
//     "couponExpirationTimestamp",
//     newExpirationTimestamp.toString()
//   );
//   return newExpirationTimestamp;
// };

// const calculateTimeLeft = (expirationTimestamp) => {
//   const difference = expirationTimestamp - new Date().getTime();

//   if (difference > 0) {
//     return {
//       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//       minutes: Math.floor((difference / 1000 / 60) % 60),
//       seconds: Math.floor((difference / 1000) % 60),
//     };
//   }

//   return { days: 0, hours: 0, minutes: 0, seconds: 0 };
// };

// const formatTimeLeft = (timeLeft) => {
//   const { days, hours, minutes, seconds } = timeLeft;
//   let result = "";

//   if (days > 0) result += `${days}d `;
//   if (hours > 0) result += `${hours}h `;
//   result += `${minutes}m ${seconds}s`;

//   return result;
// };

// const CouponDisplay = React.memo(({ coupon, onClose }) => {
//   const [timeLeft, setTimeLeft] = useState(() =>
//     calculateTimeLeft(getExpirationTimestamp())
//   );
//   const [isCopied, setIsCopied] = useState(false);
//   const [isVisible, setIsVisible] = useState(true);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const restartCountdown = useCallback(() => {
//     const newExpirationTimestamp =
//       new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
//     localStorage.setItem(
//       "couponExpirationTimestamp",
//       newExpirationTimestamp.toString()
//     );
//     setTimeLeft(calculateTimeLeft(newExpirationTimestamp));
//   }, []);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       const updatedTimeLeft = calculateTimeLeft(getExpirationTimestamp());
//       setTimeLeft(updatedTimeLeft);

//       if (Object.values(updatedTimeLeft).every((value) => value === 0)) {
//         restartCountdown();
//       }
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [restartCountdown]);

//   const formattedTimeLeft = useMemo(() => formatTimeLeft(timeLeft), [timeLeft]);

//   const handleCopyCode = useCallback(() => {
//     navigator.clipboard.writeText(coupon?.CouponCode).then(() => {
//       setIsCopied(true);
//       setTimeout(() => setIsCopied(false), 2000);
//     });
//   }, [coupon?.CouponCode]);

//   if (!coupon || !isVisible) {
//     return null;
//   }

//   const { CouponCode, Description, DiscountValue } = coupon;

//   return (
//     <Slide direction="down" in={isVisible} mountOnEnter unmountOnExit>
//       <StyledBanner>
//         <Typography
//           variant={isMobile ? "body2" : "body1"}
//           component="span"
//           sx={{ color: "#FFFFFF" }}
//         >
//           {Description}
//         </Typography>
//         <Chip
//           label={`${CouponCode}`}
//           sx={{
//             backgroundColor: "#FFD93D",
//             color: "black",
//             fontWeight: "bold",
//           }}
//           onClick={handleCopyCode}
//           icon={<ContentCopy sx={{ color: "grey !important" }} />}
//         />
//         <Typography
//           variant={isMobile ? "body2" : "body1"}
//           component="span"
//           sx={{ color: "#FFFFFF" }}
//         >
//           ${DiscountValue}% OFF Ends in
//         </Typography>
//         <TimerChip
//           icon={<AccessTime sx={{ color: "#FFFFFF !important" }} />}
//           label={formattedTimeLeft}
//           variant="filled"
//         />

//         <Snackbar
//           anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//           open={isCopied}
//           autoHideDuration={2000}
//           onClose={() => setIsCopied(false)}
//           message="Coupon code copied to clipboard!"
//           TransitionComponent={Fade}
//         />
//       </StyledBanner>
//     </Slide>
//   );
// });

// export default CouponDisplay;
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Chip,
  Slide,
  useMediaQuery,
  IconButton,
  Tooltip,
  Snackbar,
  Fade,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  LocalFireDepartment,
  AccessTime,
  ContentCopy,
  Close,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const StyledBanner = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: 0,
  width: "100%",
  backgroundColor: "#27B1BB",
  padding: theme.spacing(1),
  textAlign: "center",
  fontSize: "18px",
  color: "#FFFFFF",
  zIndex: 999,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));

const TimerChip = styled(Chip)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "#FF6B6B",
  color: "#FFFFFF",
}));

const PulsatingChip = styled(Chip)(({ theme }) => ({
  animation: "pulse 2s infinite",
  "@keyframes pulse": {
    "0%": {
      boxShadow: "0 0 0 0 rgba(255, 217, 61, 0.7)",
    },
    "70%": {
      boxShadow: "0 0 0 10px rgba(255, 217, 61, 0)",
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(255, 217, 61, 0)",
    },
  },
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const getExpirationTimestamp = () => {
  const storedTimestamp = localStorage.getItem("couponExpirationTimestamp");

  if (storedTimestamp) {
    return parseInt(storedTimestamp, 10);
  }

  const newExpirationTimestamp =
    new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
  localStorage.setItem(
    "couponExpirationTimestamp",
    newExpirationTimestamp.toString()
  );
  return newExpirationTimestamp;
};

const calculateTimeLeft = (expirationTimestamp) => {
  const difference = expirationTimestamp - new Date().getTime();

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return { days: 0, hours: 0, minutes: 0, seconds: 0 };
};

const formatTimeLeft = (timeLeft) => {
  const { days, hours, minutes, seconds } = timeLeft;
  let result = "";

  if (days > 0) result += `${days}d `;
  if (hours > 0) result += `${hours}h `;
  result += `${minutes}m ${seconds}s`;

  return result;
};

const CouponDisplay = React.memo(({ coupon, onClose }) => {
  const [timeLeft, setTimeLeft] = useState(() =>
    calculateTimeLeft(getExpirationTimestamp())
  );
  const [isCopied, setIsCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const restartCountdown = useCallback(() => {
    const newExpirationTimestamp =
      new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
    localStorage.setItem(
      "couponExpirationTimestamp",
      newExpirationTimestamp.toString()
    );
    setTimeLeft(calculateTimeLeft(newExpirationTimestamp));
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft(getExpirationTimestamp());
      setTimeLeft(updatedTimeLeft);

      if (Object.values(updatedTimeLeft).every((value) => value === 0)) {
        restartCountdown();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [restartCountdown]);

  const formattedTimeLeft = useMemo(() => formatTimeLeft(timeLeft), [timeLeft]);

  const handleCopyCode = useCallback(() => {
    navigator.clipboard.writeText(coupon?.CouponCode).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  }, [coupon?.CouponCode]);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      if (onClose) onClose();
    }, 300);
  }, [onClose]);

  if (!coupon || !isVisible) {
    return null;
  }

  const { CouponCode, Description, DiscountValue } = coupon;

  return (
    <Slide direction="down" in={isVisible} mountOnEnter unmountOnExit>
      <StyledBanner>
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
          spacing={1}
          width="100%"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <LocalFireDepartment sx={{ color: "#FF6B6B" }} /> */}
            <Typography
              variant={isMobile ? "body2" : "body1"}
              component="span"
              sx={{ color: "#FFFFFF", fontWeight: "bold" }}
            >
              {Description} Coupon Code:
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            flexWrap="wrap"
            justifyContent="center"
          >
            {/* <PulsatingChip
              label={`${CouponCode}`}
              sx={{
                backgroundColor: "#FFD93D",
                color: "black",
                fontWeight: "bold",
              }}
              onClick={handleCopyCode}
              icon={<ContentCopy sx={{ color: "grey !important" }} />}
            /> */}
            <PulsatingChip
              label={`${CouponCode}`}
              sx={{
                backgroundColor: "#FFD93D",
                color: "black",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#ffeb3b",
                  cursor: "pointer",
                },
              }}
              onClick={handleCopyCode}
              icon={<ContentCopy sx={{ color: "grey !important" }} />}
            />
            <Typography
              variant={isMobile ? "body2" : "body1"}
              component="span"
              sx={{ color: "#FFFFFF", fontWeight: "bold" }}
            >
              ${DiscountValue}% OFF
            </Typography>
            <TimerChip
              icon={<AccessTime sx={{ color: "#FFFFFF !important" }} />}
              label={formattedTimeLeft}
              variant="filled"
              size="small"
            />
          </Stack>
          {/* {!isMobile && (
            <Tooltip title="Close">
              <IconButton
                size="small"
                onClick={handleClose}
                aria-label="close coupon banner"
              >
                <Close sx={{ color: "#FFFFFF" }} />
              </IconButton>
            </Tooltip>
          )} */}
        </Stack>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isCopied}
          autoHideDuration={2000}
          onClose={() => setIsCopied(false)}
          message="Coupon code copied to clipboard!"
          TransitionComponent={Fade}
        />
      </StyledBanner>
    </Slide>
  );
});

export default CouponDisplay;

// import React, { useState, useEffect, useCallback, useMemo } from "react";
// import {
//   Box,
//   Typography,
//   Chip,
//   Slide,
//   useMediaQuery,
//   IconButton,
//   Tooltip,
//   Snackbar,
//   Fade,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import {
//   LocalFireDepartment,
//   WbSunny,
//   AccessTime,
//   ContentCopy,
//   Close,
// } from "@mui/icons-material";
// import { useTheme } from "@mui/material/styles";

// const StyledBanner = styled(Box)(({ theme }) => ({
//   position: "fixed",
//   left: 0,
//   width: "100%",
//   backgroundColor: "#27B1BB",
//   padding: theme.spacing(1),
//   textAlign: "center",
//   fontSize: "18px",
//   color: "#FFFFFF",
//   zIndex: 998,
//   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   flexWrap: "wrap",
//   gap: theme.spacing(1),
// }));

// const TimerChip = styled(Chip)(({ theme }) => ({
//   fontWeight: "bold",
//   backgroundColor: "#FF6B6B",
//   color: "#FFFFFF",
// }));

// const CouponChip = styled(Chip)(({ theme }) => ({
//   backgroundColor: theme.palette.secondary.main,
//   color: theme.palette.secondary.contrastText,
//   fontWeight: "bold",
//   "&:hover": {
//     backgroundColor: theme.palette.secondary.dark,
//   },
// }));

// const PulsatingChip = styled(Chip)(({ theme }) => ({
//   animation: "pulse 2s infinite",
//   "@keyframes pulse": {
//     "0%": {
//       boxShadow: "0 0 0 0 rgba(255, 217, 61, 0.7)",
//     },
//     "70%": {
//       boxShadow: "0 0 0 10px rgba(255, 217, 61, 0)",
//     },
//     "100%": {
//       boxShadow: "0 0 0 0 rgba(255, 217, 61, 0)",
//     },
//   },
//   transition: "transform 0.3s ease-in-out",
//   "&:hover": {
//     transform: "scale(1.05)",
//   },
// }));

// const getExpirationTimestamp = () => {
//   const storedTimestamp = localStorage.getItem("couponExpirationTimestamp");

//   if (storedTimestamp) {
//     return parseInt(storedTimestamp, 10);
//   }

//   const newExpirationTimestamp =
//     new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
//   localStorage.setItem(
//     "couponExpirationTimestamp",
//     newExpirationTimestamp.toString()
//   );
//   return newExpirationTimestamp;
// };

// const calculateTimeLeft = (expirationTimestamp) => {
//   const difference = expirationTimestamp - new Date().getTime();

//   if (difference > 0) {
//     return {
//       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//       minutes: Math.floor((difference / 1000 / 60) % 60),
//       seconds: Math.floor((difference / 1000) % 60),
//     };
//   }

//   return { days: 0, hours: 0, minutes: 0, seconds: 0 };
// };

// const formatTimeLeft = (timeLeft) => {
//   const { days, hours, minutes, seconds } = timeLeft;
//   let result = "";

//   if (days > 0) result += `${days}d `;
//   if (hours > 0) result += `${hours}h `;
//   result += `${minutes}m ${seconds}s`;

//   return result;
// };

// const CouponDisplay = React.memo(({ coupon, onClose }) => {
//   const [timeLeft, setTimeLeft] = useState(() =>
//     calculateTimeLeft(getExpirationTimestamp())
//   );
//   const [isCopied, setIsCopied] = useState(false);
//   const [isVisible, setIsVisible] = useState(true);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const restartCountdown = useCallback(() => {
//     const newExpirationTimestamp =
//       new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
//     localStorage.setItem(
//       "couponExpirationTimestamp",
//       newExpirationTimestamp.toString()
//     );
//     setTimeLeft(calculateTimeLeft(newExpirationTimestamp));
//   }, []);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       const updatedTimeLeft = calculateTimeLeft(getExpirationTimestamp());
//       setTimeLeft(updatedTimeLeft);

//       if (Object.values(updatedTimeLeft).every((value) => value === 0)) {
//         restartCountdown();
//       }
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [restartCountdown]);

//   const formattedTimeLeft = useMemo(() => formatTimeLeft(timeLeft), [timeLeft]);

//   const handleCopyCode = useCallback(() => {
//     navigator.clipboard.writeText(coupon?.CouponCode).then(() => {
//       setIsCopied(true);
//       setTimeout(() => setIsCopied(false), 2000);
//     });
//   }, [coupon?.CouponCode]);

//   const handleClose = useCallback(() => {
//     setIsVisible(false);
//     setTimeout(() => {
//       if (onClose) onClose();
//     }, 300);
//   }, [onClose]);

//   if (!coupon || !isVisible) {
//     return null;
//   }

//   const { CouponCode, Description, DiscountValue } = coupon;

//   return (
//     <Slide direction="down" in={isVisible} mountOnEnter unmountOnExit>
//       <StyledBanner>
//         {/* <LocalFireDepartment sx={{ color: "#FF6B6B" }} /> */}
//         <Typography
//           variant={isMobile ? "body2" : "h6"}
//           component="span"
//           sx={{ color: "#FFFFFF", fontWeight: "bold" }}
//         >
//           Exclusive Offer:
//         </Typography>
//         <Typography
//           variant={isMobile ? "body2" : "body1"}
//           component="span"
//           sx={{ color: "#FFFFFF" }}
//         >
//           {Description}
//         </Typography>
//         <PulsatingChip
//           label={`${CouponCode}`}
//           sx={{
//             backgroundColor: "#FFD93D",
//             color: "black",
//             fontWeight: "bold",
//           }}
//           onClick={handleCopyCode}
//           icon={<ContentCopy sx={{ color: "grey !important" }} />}
//         />
//         <Typography
//           variant={isMobile ? "body2" : "h6"}
//           component="span"
//           sx={{ color: "#FFD93D", fontWeight: "bold" }}
//         >
//           {DiscountValue}% OFF
//         </Typography>
//         {/* <WbSunny sx={{ color: "#FFD93D" }} /> */}
//         <Typography
//           variant={isMobile ? "body2" : "body1"}
//           component="span"
//           sx={{ color: "#FFFFFF" }}
//         >
//           Hurry! Ends in
//         </Typography>
//         <TimerChip
//           icon={<AccessTime sx={{ color: "#FFFFFF !important" }} />}
//           label={formattedTimeLeft}
//           variant="filled"
//           sx={{
//             fontSize: isMobile ? "0.8rem" : "1rem",
//             padding: "4px 8px",
//           }}
//         />
//         {/* <Tooltip title="Close">
//           <IconButton
//             size="small"
//             onClick={handleClose}
//             aria-label="close coupon banner"
//           >
//             <Close />
//           </IconButton>
//         </Tooltip> */}
//         <Snackbar
//           anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//           open={isCopied}
//           autoHideDuration={2000}
//           onClose={() => setIsCopied(false)}
//           message="Coupon code copied! Ready to save big!"
//           TransitionComponent={Fade}
//           ContentProps={{
//             sx: {
//               background: "#4CAF50",
//               color: "#FFFFFF",
//               fontWeight: "bold",
//             },
//           }}
//         />
//       </StyledBanner>
//     </Slide>
//   );
// });

// export default CouponDisplay;
