import {
  useState,
  useEffect,
  useContext,
  Suspense,
  useCallback,
  useRef,
  lazy,
  memo,
} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircle from "@mui/icons-material/AccountCircle";
import useMediaQuery from "@mui/material/useMediaQuery";
// import {  } from "react-router-dom";
// import { Link as RouterLink } from "react-router-dom";
import cartIcon from "../assets/cart icon.svg";
import { AppContext } from "../App";
import { ShopingItem } from "./styles";
import logo from "../assets/DirectCertify.svg";
import "./style.css";
import CartSkeletonLoading from "../pricing/CartSkeletonloading";
const Cart = lazy(() => import("../pricing/Cart"));
const Login = lazy(() => import("../authentication/Auth/LoginComponent"));
const SearchModal = lazy(() => import("./SearchModel"));
const examscategory = [
  { ExamVendorId: 63, ExamVendorName: "Cisco" },
  { ExamVendorId: 21, ExamVendorName: "CompTIA" },
  { ExamVendorId: 13, ExamVendorName: "Fortinet" },
  { ExamVendorId: 115, ExamVendorName: "HP" },
  { ExamVendorId: 214, ExamVendorName: "Microsoft" },
  { ExamVendorId: 22, ExamVendorName: "Salesforce" },
  { ExamVendorId: 18, ExamVendorName: "VMware" },
  { ExamVendorId: 14, ExamVendorName: "SAP" },
  { ExamVendorId: 20, ExamVendorName: "Oracle" },
  { ExamVendorId: 36, ExamVendorName: "Nursing" },
];
const drawerWidth = 280;

const Search = styled(Box)({
  position: "relative",
  background: "#F9F9F9",
  border: "0.5px solid #E7E7E7",
  borderRadius: "4px",
  marginLeft: "30px",
  width: "74%",
  height: "40px",
});
const NavButton = styled(Button)(({ theme }) => ({
  color: "#565D6D",
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "19px",
  letterSpacing: "0.005em",
  textTransform: "none",
  [theme.breakpoints.down(1300)]: {
    padding: "0px",
    lineHeight: "16px",
    fontSize: "14px",
  },
  [theme.breakpoints.down(1050)]: {
    lineHeight: "10px",
    fontSize: "10px",
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#B1B1B1",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    readOnly: true,
    userSelect: "none",
    cursor: "pointer",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Appbar = memo(function Appbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [anchorel, setAnchorEl] = useState(null);
  const [anchore2, setAnchorE2] = useState(null);
  const [examCats, setExamCats] = useState([]);
  const timeoutIdRef = useRef(null);
  useEffect(() => {
    setExamCats([...examscategory]);
  }, []);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("shoppingUserDetails"))
    ? JSON.parse(localStorage.getItem("shoppingUserDetails"))
    : null;
  const [ischangepass, setischangepass] = useState(false);
  const [isdashboard, setisdashboard] = useState(false);
  const navigate = useNavigate();
  const { token, setUserToken, cardItems, showCart, toggle } =
    useContext(AppContext);
  const [FirstName, SetFirstName] = useState("");
  const location = useLocation();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width:900px)");

  const handleOpenSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const handleCloseSearchModal = () => {
    setIsSearchModalOpen(false);
  };
  useEffect(() => {
    const currentURL = location.pathname;
    setischangepass(!currentURL.includes("userdashboard"));
    setisdashboard(!currentURL.includes("editProfile"));
  }, [location.pathname]);

  useEffect(() => {
    SetFirstName(currentUser?.FirstName);
  }, [currentUser]);
  useEffect(() => {
    setMobileOpen(false);
  }, []);

  const handleProfile = useCallback(() => {
    navigate("/userdashboard/editProfile");
    setAnchorEl(null);
  }, [navigate]);
  const handleDrawerToggle = useCallback(() => {
    if (isMobile) {
      setMobileOpen((prevState) => !prevState);
    }
  }, [isMobile]);

  const loginToggle = useCallback(() => {
    setLoginModal((prev) => !prev);
  }, []);
  const navtoggle = useCallback(() => {
    setLoginModal(false);
    setRegisterModal((prev) => !prev);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setMobileOpen(false);
  }, []);
  const handleCloseE2 = useCallback(() => {
    setAnchorE2(null);
    setAnchorEl(null);
    setMobileOpen(true);
  }, []);
  // const handleMenu = useCallback(
  //   (event) => {
  //     if (isMobile) {
  //       setMobileOpen((prevState) => !prevState);
  //     } else {
  //       setAnchorEl(event.currentTarget);
  //     }
  //   },
  //   [isMobile]
  // );
  const handleMenu = useCallback(
    (event) => {
      if (isMobile) {
        setAnchorEl(event.currentTarget);
        setMobileOpen(false);
      } else {
        setAnchorEl(event.currentTarget);
      }
    },
    [isMobile]
  );
  const handleCategories = useCallback((event) => {
    setAnchorE2(event.currentTarget);
  }, []);

  const navigateToCategory = useCallback(
    (category) => {
      navigate(`/${category}`);
      setDropdownVisible(false);
    },
    [navigate]
  );

  const handleLogout = useCallback(() => {
    setUserToken(false);

    // Save the values of "couponExpirationTimestamp" and "coupon"
    let couponExpirationTimestampValue = localStorage.getItem(
      "couponExpirationTimestamp"
    );
    let couponValue = localStorage.getItem("coupon");

    // Clear all keys
    localStorage.clear();

    // Restore the values of "couponExpirationTimestamp" and "coupon" if they were not null
    if (couponExpirationTimestampValue !== null) {
      localStorage.setItem(
        "couponExpirationTimestamp",
        couponExpirationTimestampValue
      );
    }
    if (couponValue !== null) {
      localStorage.setItem("coupon", couponValue);
    }

    navigate("/");
    setAnchorE2(null);
    setAnchorEl(null);
  }, [navigate, setUserToken]);

  const clearDropdownTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  };

  const handleMouseEnter = useCallback(() => {
    clearDropdownTimeout();
    setDropdownVisible(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timeoutIdRef.current = setTimeout(() => {
      setDropdownVisible(false);
    }, 200);
  }, []);

  const handleDropdownMouseEnter = useCallback(() => {
    clearDropdownTimeout();
  }, []);

  const handleDropdownMouseLeave = useCallback(() => {
    timeoutIdRef.current = setTimeout(() => {
      setDropdownVisible(false);
    }, 200);
  }, []);

  useEffect(() => {
    return () => {
      clearDropdownTimeout();
    };
  }, []);
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Stack direction="row" justifyContent="space-evenly">
        <Box
          component="img"
          src={logo}
          alt="DirectCertify logo"
          loading="lazy"
          width={150}
          height={50} // Assuming a 3:1 aspect ratio, adjust if different
          sx={{
            height: "auto",
            maxHeight: "50px",
            objectFit: "contain",
            mt: 0,
            py: 1,
            aspectRatio: "150 / 50",
          }}
        />
        {ischangepass && (
          <Menu
            id="menu-appbar"
            anchorEl={anchore2}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchore2)}
            onClose={handleCloseE2}
          >
            {examCats.map((category) => (
              <MenuItem
                key={category.ExamVendorId}
                onClick={() => {
                  handleCloseE2();
                  navigate(`/${category.ExamVendorName}`);
                }}
                sx={{ fontSize: "14px" }}
              >
                {category.ExamVendorName}
              </MenuItem>
            ))}
            <Button
              component={Link}
              to="/categories"
              sx={{
                mt: 2,
                backgroundColor: "#27b1bb",
                color: "white",
                "&:hover": {
                  backgroundColor: "#1f8f94",
                },
              }}
              onClick={handleCloseE2}
            >
              All Categories
            </Button>
          </Menu>
        )}

        {token && (
          <>
            <Box
              sx={{
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AccountCircle
                sx={{
                  cursor: "pointer",
                  fontSize: "30px",
                  color: "#27B1BB",
                }}
                onClick={handleMenu}
              />
              <Typography sx={{ fontSize: "14px", color: "black" }}>
                {FirstName}
              </Typography>
            </Box>

            <Menu
              id="menu-appbar"
              anchorEl={anchorel}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorel)}
              onClose={handleClose}
            >
              {!isdashboard ? null : (
                <MenuItem onClick={handleProfile}>Dashboard</MenuItem>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Stack>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", textTransform: "none" }}
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", textTransform: "none" }}
            onClick={handleCategories}
          >
            Categories <KeyboardArrowDownIcon />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", textTransform: "none" }}
            onClick={() => {
              navigate("/faqs");
            }}
          >
            FAQs
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", textTransform: "none" }}
            onClick={() => {
              navigate("/about-us");
            }}
          >
            About Us
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", textTransform: "none" }}
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            Contact Us
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", textTransform: "none" }}
            onClick={() => {
              navigate("/refund-policy");
            }}
          >
            Refund Policy
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", textTransform: "none" }}
            onClick={() => {
              navigate("/privacy-policy");
            }}
          >
            Privacy Policy
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      {!token && (
        <Button
          size="small"
          variant="contained"
          sx={{
            background: "#27B1BB !important",
            textTransform: "none",
          }}
          onClick={loginToggle}
        >
          <PersonIcon sx={{ color: "white", mr: 1 }} /> Login / Register
        </Button>
      )}
    </Box>
  );

  return (
    <>
      <Box sx={{ display: "flex", zIndex: 1 }}>
        <AppBar
          component="nav"
          sx={{
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
            backgroundColor: "white",
            zIndex: "999",
            marginTop: !location.pathname.startsWith("/userdashboard")
              ? // ? { xs: "52px", md: "31px" }
                { xs: "90px", md: "44px" }
              : 0, // If the current path starts with "/userdashboard", the marginTop will be 0
          }}
        >
          <Toolbar
            className="width"
            sx={{
              width: "100%",
              margin: "0 auto",
              background: "#FFFFFF",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                component="div"
                sx={{
                  padding: "0 !important",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box
                  component="img"
                  src={logo}
                  alt="DirectCertify"
                  width={180}
                  height={50}
                  loading="eager"
                  sx={{
                    width: {
                      xs: "140px", // Smaller size for extra small screens
                      sm: "160px", // Small screens
                      md: "180px", // Medium screens and up
                    },
                    height: "auto", // Maintain aspect ratio
                    maxHeight: "50px",
                    objectFit: "contain",
                    aspectRatio: "180 / 50",
                  }}
                />
              </Button>
            </Link>
            <Stack direction="row" width="100%" justifyContent="space-between">
              {!ischangepass ? null : (
                <Stack
                  direction="row"
                  width={{ xs: "100%", sm: "100%", md: "20%" }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon sx={{ color: "#B1B1B1" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      onClick={handleOpenSearchModal}
                      readOnly
                    />
                  </Search>

                  {isSearchModalOpen && (
                    <Suspense
                      fallback={
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            display: "grid",
                            height: "100vh",
                            width: "100vw",
                            placeItems: "center",
                            backgroundColor: "black",
                            opacity: "0.5",
                            zIndex: "999",
                          }}
                        >
                          <CircularProgress sx={{ color: "#27b1bb" }} />
                        </Box>
                      }
                    >
                      <SearchModal
                        isOpen={isSearchModalOpen}
                        handleClose={handleCloseSearchModal}
                        examscategory={examscategory}
                      />
                    </Suspense>
                  )}

                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{
                      mr: 2,
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <MenuIcon sx={{ color: "#565D6D" }} />
                  </IconButton>
                </Stack>
              )}

              <Box
                sx={{
                  mr: 4,
                  width: "100%",
                  display: { xs: "none", sm: "none", md: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {!ischangepass ? null : (
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    marginRight="30px"
                  >
                    <Link to="/">
                      <NavButton sx={{ fontWeight: 600, color: "#1A1F22" }}>
                        Home
                      </NavButton>
                    </Link>
                    <NavButton
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      sx={{ textTransform: "none" }}
                    >
                      Categories <KeyboardArrowDownIcon />
                    </NavButton>

                    {isDropdownVisible && (
                      <Stack
                        className="stack-firefox"
                        onMouseEnter={handleDropdownMouseEnter}
                        onMouseLeave={handleDropdownMouseLeave}
                        sx={{
                          flexWrap: "wrap",
                          position: "absolute",
                          top: "90%",
                          left: { xs: "unset", lg: "28%" },
                          zIndex: 10,
                          bgcolor: "white",
                          boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.2)",
                          p: 1,
                          maxHeight: "140px",
                          alignItems: "flex-start",
                          border: "solid 3px #27b1bb",
                        }}
                      >
                        {examCats.map((category) => (
                          <NavButton
                            key={category.ExamVendorId}
                            component={Link}
                            to={`/${encodeURIComponent(
                              category.ExamVendorName.replace(/ /g, "-")
                            ).toLowerCase()}`}
                            sx={{
                              fontSize: "14px",
                              justifyContent: "flex-start",
                              px: 1,
                              py: 2,
                              textDecoration: "underline",
                              "&:hover": {
                                backgroundColor: "#27b1bb",
                                color: "white",
                              },
                            }}
                            onClick={() =>
                              navigateToCategory(category.ExamVendorName)
                            }
                          >
                            {category.ExamVendorName}
                          </NavButton>
                        ))}
                        <Button
                          component={Link}
                          to="/categories"
                          sx={{
                            m: 2,
                            backgroundColor: "#27b1bb",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#1f8f94",
                            },
                          }}
                          onClick={() => setDropdownVisible(false)}
                        >
                          All Categories
                        </Button>
                      </Stack>
                    )}
                    <Link to="/faqs">
                      <NavButton sx={{ textTransform: "none" }}>FAQs</NavButton>
                    </Link>
                    <Link to="/about-us">
                      <NavButton sx={{ textTransform: "none" }}>
                        About Us
                      </NavButton>
                    </Link>
                    <Link to="/contact-us">
                      <NavButton sx={{ textTransform: "none" }}>
                        Contact Us
                      </NavButton>
                    </Link>
                    <Link to="/refund-policy">
                      <NavButton sx={{ textTransform: "none" }}>
                        Refund Policy
                      </NavButton>
                    </Link>
                  </Stack>
                )}
                {/* {ischangepass &&
                  !location.pathname.includes("payment") &&
                  !location.pathname.includes("cart") &&
                  token && ( */}
                {ischangepass &&
                  !location.pathname.includes("payment") &&
                  !location.pathname.includes("cart") &&
                  (token
                    ? !location.pathname.includes("userdashboard")
                    : true) && (
                    <Box display={"flex"}>
                      <img
                        className="img-xl"
                        src={cartIcon}
                        alt="Shopping Cart Icon"
                        width="28"
                        height="28"
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          mx: 1,
                          color: "#565D6D",
                          ml: { sm: 4 },
                        }}
                        onClick={toggle}
                      />
                      <ShopingItem>{cardItems}</ShopingItem>
                    </Box>
                  )}
                {token ? (
                  <>
                    <Box
                      className="right-aligned-content"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AccountCircle
                        sx={{
                          cursor: "pointer",
                          fontSize: "30px",
                          color: "#27B1BB",
                        }}
                        onClick={handleMenu}
                      />
                      <Typography
                        sx={{ ml: 0.5, fontSize: "14px", color: "black" }}
                      >
                        {FirstName}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      mr: 4,
                      ml: { sm: 0, xl: "-10px !important" },
                      background: "#27B1BB !important",
                      padding: "0.2rem 0.75rem",
                      textTransform: "none",
                      flexShrink: 0,
                    }}
                    onClick={loginToggle}
                  >
                    <PersonIcon sx={{ color: "white", mr: 1 }} /> Login/Register
                  </Button>
                )}
              </Box>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {loginModal && (
        <Suspense fallback={<div>Loading...</div>}>
          <Login open={loginModal} onClose={loginToggle} onReg={navtoggle} />
        </Suspense>
      )}

      <Stack width="100%" justifyContent="flex-end">
        {showCart && (
          <Suspense fallback={<CartSkeletonLoading />}>
            <Cart open={showCart} onClose={toggle} />
          </Suspense>
        )}
      </Stack>
    </>
  );
});

export default Appbar;
